import { InternalErrorKind, mkErr } from "@redwit-commons/utils/exception2";
import {
  mkAuthHeader,
  mkGetURL,
  mkJSONHeader,
} from "@redwit-commons/utils/request";

/* 모드 별 환경 변수 */
const ENV_OBJS = {
  staging: {
    // API_SERVER: "https://dev.redwit.io:443",
    API_SERVER: "https://stg-api.goono.xyz",
    IPFS_API_SERVER: "https://stg-ipfs-api.goono.xyz",
  },
  // test: {
  //   API_SERVER: "https://test-goono-api.flame.redwit.io",
  // },
  production: {
    // API_SERVER: "http://goono-api.redwit.io",
    API_SERVER: "https://prd-api.goono.xyz",
    IPFS_API_SERVER: "https://prd-ipfs-api.goono.xyz",
  },
  local: {
    API_SERVER: "http://localhost:8001",
    IPFS_API_SERVER: "http://localhost:7001",
  },
};

const defaultEnv: ENV_TYPE =
  process.env.REACT_APP_TEST_ENABLED === "1" ||
  process.env.REACT_APP_TEST_ENABLED === "y"
    ? "staging"
    : "production";
export let ENV = ENV_OBJS[defaultEnv];
export type ENV_TYPE = "production" | "staging" | "local";
export function changeEnv(type: ENV_TYPE) {
  ENV = ENV_OBJS[type];
}

const getAPIHost = () => {
  return ENV.API_SERVER;
};

/**
 * Request Interface
 */
type HTTPMethod =
  | "DELETE"
  | "GET"
  | "HEAD"
  | "PATCH"
  | "POST"
  | "PUT"
  | "OPTIONS";

const doGetRequest = async (
  host: string,
  endpoint: string,
  token?: string,
  queryObj?: { [key: string]: unknown }
): Promise<unknown> => {
  const response = await fetch(mkGetURL(host, endpoint, queryObj), {
    method: "GET",
    headers: token ? mkAuthHeader(token) : undefined, // any: react-native 사용을 위함
  }).catch((err) => {
    throw mkErr({
      kind: InternalErrorKind.Network,
      loc: "doGetRequest::fetch",
      host,
      endpoint,
      token,
      queryObj,
      err,
    });
  });
  if (!response.ok) {
    /// 응답이 왔는데 에러인게 더 hard 함
    /// TODO 혹시 이를 다른 에러 타입으로 구분해야 하나?
    const msg = await response.json().catch((_) => undefined);
    throw mkErr({
      kind: InternalErrorKind.ResponseCode,
      loc: "doGetRequest::response",
      code: response.status,
      msg,
    });
  }
  if (
    response.headers.get("Content-Type") === "text/csv" ||
    response.headers.get("Content-Type") === "application/msword"
  ) {
    return await response.blob();
  }
  return await response.json();
};

const doWriteRequest = async (
  host: string,
  method: HTTPMethod,
  endpoint: string,
  token?: string,
  bodyObj?: unknown
): Promise<unknown> => {
  const response = await fetch(mkGetURL(host, endpoint), {
    method,
    headers: {
      ...(bodyObj ? mkJSONHeader() : undefined),
      ...(token ? mkAuthHeader(token) : undefined),
    },
    body: bodyObj ? JSON.stringify(bodyObj) : undefined,
  }).catch((err) => {
    throw mkErr({
      kind: InternalErrorKind.Network,
      loc: "doWriteRequest::fetch",
      host,
      endpoint,
      token,
      bodyObj,
      err,
    });
  });
  if (!response.ok) {
    /// 응답이 왔는데 에러인게 더 hard 함
    /// TODO 혹시 이를 다른 에러 타입으로 구분해야 하나?
    const msg = await response.json().catch((_) => undefined);
    throw mkErr({
      kind: InternalErrorKind.ResponseCode,
      loc: "doWriteRequest::response",
      code: response.status,
      msg,
    });
  }
  return await response.json();
};

// XXX 왜 any 대신 unknown 쓰면 에러나는지는 모르겠음
/* eslint-disable @typescript-eslint/no-explicit-any */
export const doAPIGetRequest = async (
  endpoint: string,
  token?: string,
  queryObj?: { [key: string]: any }
) => {
  return await doGetRequest(getAPIHost(), endpoint, token, queryObj);
};
/* eslint-enable @typescript-eslint/no-explicit-any */

const doAPIWriteRequest = async (
  method: HTTPMethod,
  endpoint: string,
  token?: string,
  bodyObj?: object
) => {
  return await doWriteRequest(getAPIHost(), method, endpoint, token, bodyObj);
};

export const doAPIPostRequest = async (
  endpoint: string,
  token?: string,
  bodyObj?: object
) => {
  return await doAPIWriteRequest("POST", endpoint, token, bodyObj);
};

export const doAPIDeleteRequest = async (
  endpoint: string,
  token?: string,
  bodyObj?: object
) => {
  return await doAPIWriteRequest("DELETE", endpoint, token, bodyObj);
};

export const doAPIPutRequest = async (
  endpoint: string,
  token?: string,
  bodyObj?: object
) => {
  return await doAPIWriteRequest("PUT", endpoint, token, bodyObj);
};

export const doAPIPatchRequest = async (
  endpoint: string,
  token?: string,
  bodyObj?: object
) => {
  return await doAPIWriteRequest("PATCH", endpoint, token, bodyObj);
};
