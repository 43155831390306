import {
  AuthUpdatePWParams,
  LoginAdminParams,
  UpdateAdminPWParams,
  UpdateAdminPasswordWithTokenParams,
} from "@goono-commons/v3/request/organization";
import {
  organizationLogin,
  sendOrganizationUpdatePasswordEmail,
  updateLoginOrganizationAdminPassword,
  updateOrganizationAdminPW,
} from "@goono-react-commons/services/v4/organization";
import { delay } from "@redwit-commons/utils/function";
import { useLoginUserInfo } from "@utils/hooks/service/useLoginUserInfo";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { ScreenURL } from "src/routes/route_list";

export const useLoginMutation = () => {
  return useMutation(async (props: LoginAdminParams) => {
    return await organizationLogin(props);
  });
};

export const useLogoutMutation = () => {
  const history = useHistory();

  return useMutation(async () => {
    await delay(1);
    return history.push(ScreenURL.LOGOUT);
  });
};

export const useUpdateLoginOrganizationAdminPasswordMutation = () => {
  const user = useLoginUserInfo();

  return useMutation(async (params: UpdateAdminPWParams) => {
    return updateLoginOrganizationAdminPassword(user.token, params);
  });
};

export const useSendUpdatePasswordEmailMutation = () => {
  return useMutation(async (params: AuthUpdatePWParams) => {
    return await sendOrganizationUpdatePasswordEmail(params);
  });
};

export const useUpdatePasswordWithToken = () => {
  return useMutation(async (params: UpdateAdminPasswordWithTokenParams) => {
    return await updateOrganizationAdminPW(params);
  });
};
