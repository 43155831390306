import {
  AuthUpdatePWParams,
  LoginAdminParams,
  UpdateAdminPWParams,
  UpdateAdminPasswordWithTokenParams,
} from "@goono-commons/v3/request/organization";
import { doAPIGetRequest, doAPIPostRequest, doAPIPutRequest } from "../api";
import {
  validateOGetLoginOrganization,
  validateOGetOrganization,
  validateOGetOrganizationAdmin,
  validateOGetOrganizationWorkspaceList,
} from "@goono-commons/v3/response/organization";
import {
  OGetAdminProjects,
  validateOGetAdminProjects,
} from "@goono-commons/api/response/project";

export const getOrganization = async (token: string) => {
  const ret = await doAPIGetRequest(`/organizations/find`, token);
  return validateOGetOrganization(ret);
};

export const getOrganizationAdmin = async (token: string) => {
  const ret = await doAPIGetRequest(`/organizations/admin`, token);
  return validateOGetOrganizationAdmin(ret);
};

export const getOrganizationWorkspaceList = async (
  token: string,
  organization_id: string
) => {
  const ret = await doAPIGetRequest(
    `/organizations/workspace/list/${organization_id}`,
    token
  );
  return validateOGetOrganizationWorkspaceList(ret);
};

export const organizationLogin = async (params: LoginAdminParams) => {
  const ret = await doAPIPostRequest(`/organizations/login`, undefined, params);
  return validateOGetLoginOrganization(ret);
};

export const updateLoginOrganizationAdminPassword = async (
  token: string,
  params: UpdateAdminPWParams
) => {
  await doAPIPutRequest(`/organizations/login/update/password`, token, params);
};

export const sendOrganizationUpdatePasswordEmail = async (
  params: AuthUpdatePWParams
) => {
  await doAPIPostRequest(
    `/organizations/authentication/update/password`,
    undefined,
    params
  );
};

export const getOrganizationWorkspaceAllProjects = async (
  token: string,
  workspaceId: string
): Promise<OGetAdminProjects> => {
  const ret = validateOGetAdminProjects(
    await doAPIGetRequest(`/organizations/project/list/${workspaceId}`, token)
  );
  return ret;
};

export const updateOrganizationAdminPW = async (
  params: UpdateAdminPasswordWithTokenParams
) => {
  await doAPIPutRequest(`/organizations/update/password`, undefined, params);
};

export const getOrganizationSummaryCSV = async (
  token: string,
  organizationId: string
) => {
  const ret = (await doAPIGetRequest(
    `/organizations/dashboard/summary`,
    token,
    { organizationId }
  )) as Blob;

  return ret.text();
};
