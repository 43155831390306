import { useMutation, useQuery } from "react-query";
import {
  getOrganization,
  getOrganizationAdmin,
  getOrganizationSummaryCSV,
  getOrganizationWorkspaceAllProjects,
  getOrganizationWorkspaceList,
} from "@goono-react-commons/services/v4/organization";
import { useLoginUserInfo } from "@utils/hooks/service/useLoginUserInfo";
import { useOrganizationInfo } from "@utils/hooks/service/useOrganizationInfo";
import { isEmpty } from "@utils/functions/isEmpty";
import XLSX from "xlsx";
import { useDispatch } from "react-redux";
import {
  doSnackbarAction,
  SnackbarActionKind,
  SnackbarType,
} from "src/redux/store/reducers/snackbar";

export enum OrganizationQueryKey {
  getOrganization = "OrganizationQueryKey::getOrganization",
  getOrganizationWorkspaceList = "OrganizationQueryKey::getOrganizationWorkspaceList",
  getWorkspaceProjects = "OrganizationQueryKey::getWorkspaceProjects",
}

export const useGetOrganizationAdminMutation = () => {
  return useMutation(async (token: string) => {
    return await getOrganizationAdmin(token);
  });
};

export const useGetOrganizationQuery = () => {
  const user = useLoginUserInfo();

  return useQuery(
    [OrganizationQueryKey.getOrganization],
    async () => {
      return await getOrganization(user.token);
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
};

export const useGetOrganizationWorkspaceListQuery = () => {
  const user = useLoginUserInfo();
  const organization = useOrganizationInfo();

  return useQuery(
    [OrganizationQueryKey.getOrganizationWorkspaceList],
    async () => {
      return await getOrganizationWorkspaceList(user.token, organization.id);
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
};

export const useGetWorkspaceProjectsQuery = (workspace_id: string) => {
  const user = useLoginUserInfo();
  return useQuery(
    [OrganizationQueryKey.getWorkspaceProjects, workspace_id],
    async () => {
      if (isEmpty(workspace_id)) return [];

      return await getOrganizationWorkspaceAllProjects(
        user.token,
        workspace_id
      );
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
};

export const useDownloadOranizationSummaryCSVMutation = () => {
  const user = useLoginUserInfo();
  const organization = useOrganizationInfo();
  const dispatch = useDispatch();

  return useMutation(async () => {
    try {
      doSnackbarAction(dispatch, {
        kind: SnackbarActionKind.TRY_OPEN,
        type: SnackbarType.LOADING,
        isBackdrop: true,
        msg: "다운로드 중입니다...",
      });

      const csvText = await getOrganizationSummaryCSV(
        user.token,
        organization.id
      );

      const csvTextArray: string[][] = csvText
        .split("\n")
        .map((row) => row.split("\\,"))
        .map((subArr) => subArr.map((str) => str.replace(/"/g, "")));

      const ws = XLSX.utils.aoa_to_sheet(csvTextArray);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "워크스페이스 목록");
      XLSX.writeFile(wb, `조직개요.xlsx`);

      doSnackbarAction(dispatch, {
        kind: SnackbarActionKind.TRY_OPEN,
        type: SnackbarType.CONFIRM,
        msg: "다운로드 완료되었습니다.",
      });
    } catch {
      doSnackbarAction(dispatch, {
        kind: SnackbarActionKind.TRY_OPEN,
        type: SnackbarType.ALERT,
        msg: "문제가 발생하였습니다.",
      });
    }

    return;
  });
};
