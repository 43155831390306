import { withAccessTime } from "@redwit-commons/object/access_time";
import T from "@redwit-commons/utils/typecheck";
import { AccessTime } from "./access_time";

export enum LicenseRoleTypes {
  CORE_ADMIN = "License::Core_Admin", // 여러 라이센스를 관장하는 형태
  ADMIN = "License::Admin",
  MANAGER = "License::MANAGER",
  ASSIGEND = "License::ASSIGEND",
}

export const getRoleLevel = (role?: LicenseRoleTypes) => {
  switch (role) {
    case LicenseRoleTypes.ASSIGEND:
      return 1;
    case LicenseRoleTypes.ADMIN:
      return 2;
    case LicenseRoleTypes.MANAGER:
      return 2;
    case LicenseRoleTypes.CORE_ADMIN:
      return 3;
    default:
      return 0;
  }
};

export const LicenseRoleTypesValues = [
  LicenseRoleTypes.CORE_ADMIN,
  LicenseRoleTypes.ADMIN,
  LicenseRoleTypes.ASSIGEND,
  LicenseRoleTypes.MANAGER,
];

export const UserLicenseMapObjectSchema = withAccessTime()
  .addField("UserId", T.string())
  .addField("LicenseId", T.string())
  .addField("role", T.string().withEnum(LicenseRoleTypesValues));

export type UserLicenseMapObjectCore = {
  readonly UserId: string;
  readonly LicenseId: string;
  readonly role: LicenseRoleTypes;
};

type UserLicenseMapObject = UserLicenseMapObjectCore & AccessTime;
export const extractUserLicenseMapObject =
  T.mkObjectExtractor<UserLicenseMapObject>(UserLicenseMapObjectSchema);
