import {
  mkResponseSchema,
  mkArrayResultsSchema,
  GoonoResponse,
  GoonoArrayResponse,
  GoonoPendingTasks,
} from "./common";
import { ProjectObject, ProjectObjectSchema } from "../object/project";
import T from "@redwit-commons/utils/typecheck";
import { LinkedGithubObject, LinkedGithubObjectSchema } from "../object/github";
import {
  AdminProjectDTO,
  AdminProjectDTOSchema,
  ProjectDTO,
  ProjectDTOSchema,
  ProjectDTOSummarySchema,
  ProjectMember,
  ProjectMemberSchema,
  ProjectSummaryDTO,
} from "../../v3/project";

export enum shareTokenErrorType {
  INVALID_TOKEN = "INVALID_TOKEN",
  ALREADY_JOINED = "ALREADY_JOINED",
  PROJECT_NOT_EXISTED = "PROJECT_NOT_EXISTED",
}

/**
 * @type ProjectObject[]는 GET/projects에서만 쓰이기 때문에 일단 그대로 두었습니다.
 */
export type OGetProjects = GoonoArrayResponse<ProjectObject>;
export type OGetProject = GoonoResponse<ProjectObject>;
export type OCreateProject = GoonoResponse<ProjectObject> &
  GoonoPendingTasks<{ hlf_create_project: string }>;
export type OUpdateProject = GoonoResponse<ProjectObject>;
export type ODeleteProject = GoonoResponse<string>;
export type OShareProject = GoonoResponse<{ token: string }>;
export type OShareProjectLink = GoonoResponse<{ link: string }>;
export type OUpdateProjectOwner = GoonoResponse<ProjectObject>;

export type OJoinProject = GoonoResponse<ProjectObject> &
  GoonoPendingTasks<{ hlf_create_project: string }>;
export type ODeleteJoinedUser = GoonoResponse<string>;
export type OPinnedProject = GoonoResponse<ProjectObject>;
export type OUpdateJoinedUser = GoonoResponse<string>;
export type OCheckProjectShareToken = GoonoResponse<{
  available: boolean;
  errorMessage?: string;
}>;
export type OGetProjectLinkedGithubs = GoonoArrayResponse<LinkedGithubObject>;
export type OGetPaginationProject = {
  results: ProjectDTO[];
  total: number;
};
export type OGetNewAllProjects = Array<ProjectSummaryDTO>;
export type OGetNewProject = ProjectDTO;
export type OGetNewProjectMembers = ProjectMember[];
export type OGetAdminProjects = AdminProjectDTO[];

export const OGetProjectsSchema = mkArrayResultsSchema(ProjectObjectSchema);
export const OGetProjectSchema = mkResponseSchema(ProjectObjectSchema);
export const OCreateProjectSchema = mkResponseSchema(
  ProjectObjectSchema,
  T.object().addField("hlf_create_project", T.string())
);
export const OUpdateProjectSchema = mkResponseSchema(ProjectObjectSchema);
export const ODeleteProjectSchema = mkResponseSchema(T.string());
export const OShareProjectSchema = mkResponseSchema(
  T.object().addField("token", T.string())
);
export const OShareProjectLinkSchema = mkResponseSchema(
  T.object().addField("link", T.string())
);
export const OUpdateProjectOwnerSchema = mkResponseSchema(ProjectObjectSchema);

export const OJoinProjectSchema = mkResponseSchema(
  ProjectObjectSchema,
  T.object().addField("hlf_create_project", T.string())
);
export const ODeleteJoinedUserSchema = mkResponseSchema(T.string());
export const OPinnedProjectSchema = mkResponseSchema(ProjectObjectSchema);
export const OUpdateJoinedUserSchema = mkResponseSchema(T.string());
export const OCheckProjectShareTokenSchema = mkResponseSchema(
  T.object()
    .addField("available", T.boolean())
    .addField("errorMessage", T.string(), false)
);
export const OGetProjectLinkedGithubsSchema = mkArrayResultsSchema(
  LinkedGithubObjectSchema
);
export const OGetPaginationProjectSchema = T.object()
  .addField("results", T.array(ProjectDTOSchema))
  .addField("total", T.number());
export const OGetNewAllProjectsSchema = T.array(ProjectDTOSummarySchema);
export const OGetNewProjectSchema = ProjectDTOSchema;
export const OGetNewProjectMembersSchema = T.array(ProjectMemberSchema);
export const OGetAdminProjectsSchema = T.array(AdminProjectDTOSchema);

export const validateOGetAdminProjects = T.mkValidator<OGetAdminProjects>(
  OGetAdminProjectsSchema
);
export const validateOGetProjects =
  T.mkValidator<OGetProjects>(OGetProjectsSchema);
export const validateOGetProject =
  T.mkValidator<OGetProject>(OGetProjectSchema);
export const validateOCreateProject =
  T.mkValidator<OCreateProject>(OCreateProjectSchema);
export const validateOUpdateProject =
  T.mkValidator<OUpdateProject>(OUpdateProjectSchema);
export const validateODeleteProject =
  T.mkValidator<ODeleteProject>(ODeleteProjectSchema);
export const validateOShareProject =
  T.mkValidator<OShareProject>(OShareProjectSchema);
export const validateOShareProjectLink = T.mkValidator<OShareProjectLink>(
  OShareProjectLinkSchema
);
export const validateOUpdateProjectOwner = T.mkValidator<OUpdateProjectOwner>(
  OUpdateProjectOwnerSchema
);
export const validateOJoinProject =
  T.mkValidator<OJoinProject>(OJoinProjectSchema);
export const validateODeleteJoinedUser = T.mkValidator<ODeleteJoinedUser>(
  ODeleteJoinedUserSchema
);
export const validateOPinnedProject =
  T.mkValidator<OPinnedProject>(OPinnedProjectSchema);
export const validateOUpdateJoinedUser = T.mkValidator<OUpdateJoinedUser>(
  OUpdateJoinedUserSchema
);
export const validateOCheckProjectShareToken =
  T.mkValidator<OCheckProjectShareToken>(OCheckProjectShareTokenSchema);
export const validateOGetProjectLinkedGithubs =
  T.mkValidator<OGetProjectLinkedGithubs>(OGetProjectLinkedGithubsSchema);
export const validateOGetPaginationProject =
  T.mkValidator<OGetPaginationProject>(OGetPaginationProjectSchema);
export const validateOGetNewAllProjects = T.mkValidator<OGetNewAllProjects>(
  OGetNewAllProjectsSchema
);
export const validateOGetNewProject =
  T.mkValidator<OGetNewProject>(OGetNewProjectSchema);
export const validateOGetNewProjectMembers =
  T.mkValidator<OGetNewProjectMembers>(OGetNewProjectMembersSchema);
