import { alpha } from "@material-ui/core";

export const pxToRem = (px: number): string => {
  return px / 16 + "rem";
};

export const gds_service = {
  primary_1000: "#08204a",
  primary_900: "#0c306f",
  primary_800: "#0f4094",
  primary_700: "#1351b9",
  primary_600: "#1761de",
  primary_500: "#3578ea",
  primary_400: "#598fee",
  primary_300: "#7ea8f1",
  primary_200: "#a3c1f5",
  primary_100: "#c8daf9",
  primary_50: "#edf3fd",

  green_1000: "#0b4b2d",
  green_900: "#116f42",
  green_800: "#169257",
  green_700: "#1cb56c",
  green_600: "#21d981",
  green_500: "#41e295",
  green_400: "#64e7a9",
  green_300: "#88edbc",
  green_200: "#abf2d0",
  green_100: "#cef8e4",
  green_50: "#f2fdf8",

  cool_gray_900: "#263238",
  cool_gray_800: "#37474f",
  cool_gray_700: "#455a64",
  cool_gray_600: "#546e7a",
  cool_gray_500: "#607d8b",
  cool_gray_400: "#78909c",
  cool_gray_300: "#90a4ae",
  cool_gray_200: "#b0bec5",
  cool_gray_100: "#cfd8dc",
  cool_gray_50: "#eceff1",

  gray_900: "#212529",
  gray_800: "#343a40",
  gray_700: "#495057",
  gray_600: "#868e96",
  gray_500: "#adb5bd",
  gray_400: "#ced4da",
  gray_300: "#dee2e6",
  gray_200: "#e9ecef",
  gray_100: "#f1f3f5",
  gray_50: "#f8f9fa",

  black: "#000",
  white: "#fff",
};

export const gds_system = {
  danger_900: "#260803",
  danger_800: "#551207",
  danger_700: "#841b0b",
  danger_600: "#b2250f",
  danger_500: "#e12f13",
  danger_400: "#ee5139",
  danger_300: "#fa7561",
  danger_200: "#fca092",
  danger_100: "#fdcbc4",
  danger_50: "#fff6f5",

  warning_900: "#ff6f00",
  warning_800: "#ff8f00",
  warning_700: "#ffa000",
  warning_600: "#ffb300",
  warning_500: "#ffc107",
  warning_400: "#ffca28",
  warning_300: "#ffd54f",
  warning_200: "#ffe082",
  warning_100: "#ffecb3",
  warning_50: "#fff9e5",

  success_900: "#004d40",
  success_800: "#00695c",
  success_700: "#00796b",
  success_600: "#00897b",
  success_500: "#009688",
  success_400: "#26a69a",
  success_300: "#4db6ac",
  success_200: "#80cbc4",
  success_100: "#b2dfdb",
  success_50: "#e0f2f1",
};

export const gds_ect = {
  purple_900: "#4a148c",
  purple_800: "#6a1b9a",
  purple_700: "#7b1fa2",
  purple_600: "#8e24aa",
  purple_500: "#9c27b0",
  purple_400: "#ab47bc",
  purple_300: "#ba68c8",
  purple_200: "#ce93d8",
  purple_100: "#e1bee7",
  purple_50: "#f3e5f5",

  deep_purple_900: "#311b92",
  deep_purple_800: "#4527a0",
  deep_purple_700: "#512da8",
  deep_purple_600: "#5e35b1",
  deep_purple_500: "#673ab7",
  deep_purple_400: "#7e57c2",
  deep_purple_300: "#9575cd",
  deep_purple_200: "#b39ddb",
  deep_purple_100: "#d1c4e9",
  deep_purple_50: "#ede7f6",

  light_blue_900: "#01579b",
  light_blue_800: "#0277bd",
  light_blue_700: "#0288d1",
  light_blue_600: "#039be5",
  light_blue_500: "#03a9f4",
  light_blue_400: "#29b6f6",
  light_blue_300: "#4fc3f7",
  light_blue_200: "#81d4fa",
  light_blue_100: "#b3e5fc",
  light_blue_50: "#e1f5fe",

  pink_900: "#880e4f",
  pink_800: "#ad1457",
  pink_700: "#c2185b",
  pink_600: "#d81b60",
  pink_500: "#e91e63",
  pink_400: "#ec407a",
  pink_300: "#f06292",
  pink_200: "#f48fb1",
  pink_100: "#f8bbd0",
  pink_50: "#fce4ec",

  lime_900: "#827717",
  lime_800: "#9e9d24",
  lime_700: "#afb42b",
  lime_600: "#c0ca33",
  lime_500: "#cddc39",
  lime_400: "#d4e157",
  lime_300: "#dce775",
  lime_200: "#e6ee9c",
  lime_100: "#f0f4c3",
  lime_50: "#f9fbe7",
};

export const gds_theme = {
  max_wrap: { maxWidth: 1920 },

  max_content: { maxWidth: 1272 },
  max_content_lg: { maxWidth: 940 },
  max_content_md: { maxWidth: 932 },
  max_section_lg: { maxWidth: 872 },

  base_container: {
    /* boxSizing: "border-box", => 사용하는곳에서 추가해줘야함 */
    maxWidth: 1272 + 40,
    padding: "40px 20px 72px",

    // content의 maxWidth 1280 + 좌우 padding의 합 40
    // boxSizeing: "border-box"는 padding을 포함한 영역으로 크기를 계산해서 maxWidth를 크게 잡음
  },
  base_container_lg: {
    maxWidth: 940 + 40,
    //MuiTheme.breakpoint.down(theme.break_lg): { 에서 사용 }
  },
  base_container_md: {
    maxWidth: 932 + 40,
    padding: "32px 20px 72px",
    //MuiTheme.breakpoint.down(theme.break_md): { 에서 사용 }
  },

  project_container: {
    maxWidth: 1572 + 40,
    paddingLeft: 20,
    paddingRight: 20,
  },
  project_container_lg: {
    maxWidth: 1180 + 40,
  },
  project_container_md: {
    maxWidth: 932 + 40,
  },

  sidebar_width: 280,

  /**
   * @Dim layer background
   */
  dim_bg: {
    background: `${alpha(gds_service.black, 0.4)} !important`,
  },

  /**
   * @Shadow boxShadow 계층
   */
  shadow_1: {
    // section
    boxShadow: `0 0 4px ${alpha(gds_service.black, 0.04)}`,
  },
  shadow_2: {
    // card, header, select option
    boxShadow: `0 1px 16px ${alpha(gds_service.black, 0.06)}`,
  },
  shadow_3: {
    // more menu, popover, modal, toast, dialog, dropdown
    boxShadow: `0 4px 12px ${alpha(gds_service.gray_900, 0.19)}`,
  },
  shadow_4: {
    // card:hover
    boxShadow: `0 6px 16px ${alpha(gds_service.gray_900, 0.25)}`,
  },
  shadow_5: {
    // 미정
    boxShadow: `0 8px 20px ${alpha(gds_service.gray_900, 0.3)}`,
  },

  /**
   * @Round borderRadius
   */
  round_1: 2,
  round_2: 4,
  round_3: 8,
  round_4: 10,

  /**
   * @BorderStyles border style
   */

  border_3: `1px solid ${gds_service.gray_300}`,

  //FontSize
  /**
   * @deprecated
   */
  font10: { fontSize: 10 },
  font12: { fontSize: 12 },
  font14: { fontSize: 14 },
  font16: { fontSize: 16 },
  font18: { fontSize: 18, letterSpacing: -1 },
  font20: { fontSize: 20, letterSpacing: -1 },
  font24: { fontSize: 24, letterSpacing: -1.4 },
  font28: { fontSize: 28, letterSpacing: -1.4 },
  font32: { fontSize: 32, letterSpacing: -1.4 },

  /**
   * @Color Main color
   */
  black: gds_service.black,
  white: gds_service.white,

  gray_0: gds_service.gray_50,
  gray_1: gds_service.gray_100,
  gray_2: gds_service.gray_200,
  gray_3: gds_service.gray_300,
  gray_4: gds_service.gray_400,
  gray_5_body: gds_service.gray_500,
  gray_6: gds_service.gray_600,
  gray_7_text: gds_service.gray_700,
  gray_8: gds_service.gray_800,
  gray_9_title: gds_service.gray_900,

  primary: gds_service.primary_600,
  service_green: gds_service.green_700,
  success: gds_system.success_500,
  danger: gds_system.danger_500,
  warning: gds_system.warning_600,

  //breakpoint
  /**
   * @deprecated
   */
  MobileMode: 970,

  /**
   * @breakpoint mediaQuery breakpoint
   */
  break_xl: 1921,
  break_lg: 1281,
  break_md: 1025,
  break_sm: 601,
  break_xs: 361,
  break_xxs: 321,
  break_tablet: 821,

  /**
   * @fontWeight
   */
  notosans: {
    fontFamily: [
      "NotoSansKR",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      "sans-serif",
    ].join(","),
  },
  font_bold: { fontWeight: 700 },
  font_medium: { fontWeight: 600 },
  font_regular: { fontWeight: 500 },
  font_light: { fontWeight: 400 },

  //typo 2줄처리
  two_line_typo: {
    // whiteSpace: "pre-wrap", *사용하는곳에서 설정해줘야함
    // WebkitBoxOrient: 'vertical', *사용하는곳에서 설정해줘야함
    maxWidth: "100%",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    overflow: "hidden",
    overflowWrap: "break-word",
  },

  hidden: {
    display: "none !important",
    visibility: "hidden",
  },

  /**
   * @position
   */
  pos_center: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    // position: "absolute", *사용하는곳에서 설정해줘야함
  },
  flex_center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  /**
   * @buttonStyle Main color
   */
  btn_height_xl: { height: 48 },
  btn_height_lg: { height: 40 },
  btn_height_md: { height: 32 },
  btn_height_sm: { height: 28 },

  textbtn_height_lg: { height: 40 },
  textbtn_height_md: { height: 32 },
  textbtn_height_sm: { height: 24 },

  btn_padding_lg_noIcon: { padding: "0 24px" },
  btn_padding_md_noIcon: { padding: "0 16px" },
  btn_padding_sm_noIcon: { padding: "0 12px" },

  btn_padding_xl: 24,
  btn_padding_lg: 12,
  btn_padding_md: 8,
  btn_padding_sm: 8,

  btn_icon_xl: { width: 24, height: 24 },
  btn_icon_lg: { width: 20, height: 20 },
  btn_icon_sm: { width: 16, height: 16 },

  btn_group_gap_lg: { gap: 16 },
  btn_group_gap_md: { gap: 12 },
  btn_group_gap_sm: { gap: 8 },

  btn_transition: { transition: "background .25s, color .25s, border .25s" },

  // Icon Button
  icon_btn_size_xl: { width: 48, height: 48 },
  icon_btn_size_lg: { width: 40, height: 40 },
  icon_btn_size_md: { width: 32, height: 32 },
  icon_btn_size_sm: { width: 24, height: 24 },
};

export const gds_typo = {
  //goono typography
  /**
   * @heading
   */
  h1: {
    //h1 사이즈 미정
    fontSize: pxToRem(32),
    lineHeight: 2,
    letterSpacing: "-0.03em",
    ...gds_theme.font_bold,
  },
  h1_mobile: { fontSize: pxToRem(30) },
  h2: {
    fontSize: pxToRem(32),
    lineHeight: 1.3,
    letterSpacing: "-0.03em",
    ...gds_theme.font_bold,
  },
  h2_mobile: { fontSize: pxToRem(28) },
  h3: {
    fontSize: pxToRem(24),
    lineHeight: 1.4,
    letterSpacing: "-0.03em",
    ...gds_theme.font_bold,
  },
  h3_mobile: { fontSize: pxToRem(22) },
  h4: {
    fontSize: pxToRem(20),
    lineHeight: 1.4,
    letterSpacing: "-0.06em",
    ...gds_theme.font_bold,
  },
  h4_mobile: { fontSize: pxToRem(18) },
  h5: {
    fontSize: pxToRem(18),
    lineHeight: 1.4,
    letterSpacing: "-0.02em",
    ...gds_theme.font_bold,
  },
  h5_mobile: { fontSize: pxToRem(16) },

  /**
   * @body 본문 서체
   */
  b1_r: {
    fontSize: pxToRem(18),
    lineHeight: 1.5,
    letterSpacing: "-0.072em",
    ...gds_theme.font_regular,
  },
  b1_m: {
    fontSize: pxToRem(18),
    lineHeight: 1.5,
    letterSpacing: "-0.072em",
    ...gds_theme.font_medium,
  },
  b1_b: {
    //추가 필요
    fontSize: pxToRem(18),
    lineHeight: 1.5,
    letterSpacing: "-0.06em",
    ...gds_theme.font_bold,
  },
  b2_r: {
    fontSize: pxToRem(16),
    lineHeight: 1.58,
    letterSpacing: "-0.03em",
    ...gds_theme.font_regular,
  },
  b2_m: {
    fontSize: pxToRem(16),
    lineHeight: 1.58,
    letterSpacing: "-0.03em",
    ...gds_theme.font_medium,
  },
  b2_b: {
    fontSize: pxToRem(16),
    lineHeight: 1.58,
    letterSpacing: "-0.03em",
    ...gds_theme.font_bold,
  },
  b3_r: {
    fontSize: pxToRem(15),
    lineHeight: 1.6,
    letterSpacing: "-0.06em",
    ...gds_theme.font_regular,
  },
  b3_m: {
    fontSize: pxToRem(15),
    lineHeight: 1.6,
    letterSpacing: "-0.06em",
    ...gds_theme.font_medium,
  },
  b3_b: {
    fontSize: pxToRem(15),
    lineHeight: 1.6,
    letterSpacing: "-0.06em",
    ...gds_theme.font_bold,
  },
  b4_r: {
    fontSize: pxToRem(14),
    lineHeight: 1.6,
    letterSpacing: "-0.02em",
    ...gds_theme.font_regular,
  },
  b4_m: {
    fontSize: pxToRem(14),
    lineHeight: 1.6,
    letterSpacing: "-0.028em",
    ...gds_theme.font_medium,
  },
  b4_b: {
    fontSize: pxToRem(14),
    lineHeight: 1.6,
    letterSpacing: "-0.028em",
    ...gds_theme.font_bold,
  },

  /**
   * @deprecated caption1_b로 교체
   * **/
  b5_b: {
    fontSize: pxToRem(12),
    lineHeight: 1.7,
    letterSpacing: "-0.04em",
    ...gds_theme.font_bold,
  },

  /**
   * @element 버튼, 인터페이스 서체
   */
  ele1_m: {
    //추가 필요
    fontSize: pxToRem(16),
    lineHeight: 2,
    letterSpacing: "-0.034em",
    ...gds_theme.font_medium,
  },
  ele1_b: {
    //추가 필요
    fontSize: pxToRem(16),
    lineHeight: 2,
    letterSpacing: "-0.03em",
    ...gds_theme.font_bold,
  },
  ele2_m: {
    fontSize: pxToRem(14),
    lineHeight: 1.7,
    letterSpacing: "-0.02em",
    ...gds_theme.font_medium,
  },
  ele2_b: {
    fontSize: pxToRem(14),
    lineHeight: 1.7,
    letterSpacing: "-0.02em",
    ...gds_theme.font_bold,
  },
  ele3_m: {
    fontSize: pxToRem(12),
    lineHeight: 1.3,
    letterSpacing: "-0.02em",
    ...gds_theme.font_medium,
  },
  ele3_b: {
    fontSize: pxToRem(12),
    lineHeight: 1.3,
    letterSpacing: "-0.02em",
    ...gds_theme.font_bold,
  },

  /**
   * @caption 주석
   */
  caption1_r: {
    fontSize: pxToRem(12),
    lineHeight: 1.5,
    letterSpacing: "-0.04em",
    ...gds_theme.font_regular,
  },
  caption1_m: {
    fontSize: pxToRem(12),
    lineHeight: 1.5,
    letterSpacing: "-0.04em",
    ...gds_theme.font_medium,
  },
  caption1_b: {
    fontSize: pxToRem(12),
    lineHeight: 1.5,
    letterSpacing: "-0.04em",
    ...gds_theme.font_bold,
  },
  caption2_r: {
    fontSize: pxToRem(10),
    lineHeight: 1.6,
    letterSpacing: "-0.04em",
    ...gds_theme.font_regular,
  },
  caption2_m: {
    fontSize: pxToRem(10),
    lineHeight: 1.6,
    letterSpacing: "-0.04em",
    ...gds_theme.font_medium,
  },
  caption2_b: {
    fontSize: pxToRem(10),
    lineHeight: 1.6,
    letterSpacing: "-0.04em",
    ...gds_theme.font_bold,
  },
};

export const gds_buttons = {
  //buttons style
  size_xl: {
    padding: `0 ${gds_theme.btn_padding_xl}px`,
    ...gds_theme.btn_height_xl,
    ...gds_typo.ele1_m,
  },
  size_lg: {
    padding: `0 ${gds_theme.btn_padding_lg}px`,
    ...gds_theme.btn_height_lg,
    ...gds_typo.ele1_m,
  },
  size_md: {
    padding: `0 ${gds_theme.btn_padding_md}px`,
    ...gds_theme.btn_height_md,
    ...gds_typo.ele2_m,
  },
  size_sm: {
    padding: `0 ${gds_theme.btn_padding_sm}px`,
    ...gds_theme.btn_height_sm,
    ...gds_typo.ele3_m,
  },
  btn_base: {
    fill: "currentColor",
    border: "0",
    ...gds_theme.notosans,
  },
  btn_disabled: {
    color: `${gds_theme.gray_5_body} !important`,
    backgroundColor: `${gds_theme.gray_2} !important`,
    borderColor: `${gds_theme.gray_5_body} !important`,
  },

  btn_primary: {
    color: gds_theme.white,
    backgroundColor: gds_theme.primary,
  },
  btn_primary_hover: {
    backgroundColor: gds_service.primary_700,
    textShadow: `0 0 ${gds_theme.white}`,
  },
  btn_primary_active: {
    backgroundColor: gds_service.primary_800,
  },
  btn_primary_line: {
    color: gds_theme.primary,
    border: `1px solid ${gds_theme.primary}`,
    backgroundColor: gds_theme.white,
  },
  btn_primary_line_hover: {
    color: gds_service.primary_700,
    borderColor: gds_service.primary_700,
    backgroundColor: gds_service.primary_50,
    textShadow: `0 0 ${gds_service.primary_700}`,
  },
  btn_primary_line_active: {
    backgroundColor: gds_service.primary_100,
  },

  btn_white: {
    color: gds_theme.gray_7_text,
    backgroundColor: gds_theme.white,
  },
  btn_white_hover: {
    backgroundColor: gds_service.cool_gray_50,
    textShadow: `0 0 ${gds_theme.gray_7_text}`,
  },
  btn_white_active: {
    backgroundColor: gds_service.cool_gray_100,
  },

  btn_gray: {
    color: gds_theme.gray_7_text,
    backgroundColor: gds_theme.gray_4,
  },
  btn_gray_hover: {
    color: gds_theme.white,
    backgroundColor: gds_theme.gray_6,
    textShadow: `0 0 ${gds_theme.white}`,
  },
  btn_gray_active: {
    backgroundColor: gds_theme.gray_8,
  },

  btn_gray_line: {
    color: gds_theme.gray_7_text,
    border: `1px solid ${gds_theme.gray_3}`,
    backgroundColor: gds_theme.white,
  },
  btn_gray_line_hover: {
    color: gds_theme.gray_7_text,
    borderColor: gds_theme.gray_7_text,
    backgroundColor: gds_theme.gray_0,
    textShadow: `0 0 ${gds_theme.gray_7_text}`,
  },
  btn_gray_line_active: {
    color: gds_theme.gray_8,
    borderColor: gds_theme.gray_8,
    backgroundColor: gds_theme.gray_1,
  },

  btn_cool: {
    color: gds_theme.white,
    backgroundColor: gds_service.cool_gray_500,
  },
  btn_cool_hover: {
    color: gds_theme.white,
    backgroundColor: gds_service.cool_gray_600,
    textShadow: `0 0 ${gds_theme.white}`,
  },
  btn_cool_active: {
    backgroundColor: gds_service.cool_gray_700,
  },

  btn_cool_line: {
    color: gds_service.cool_gray_700,
    border: `1px solid ${gds_service.cool_gray_300}`,
    backgroundColor: gds_theme.white,
  },
  btn_cool_line_hover: {
    color: gds_service.cool_gray_700,
    borderColor: gds_service.cool_gray_700,
    backgroundColor: gds_service.cool_gray_50,
    textShadow: `0 0 ${gds_service.cool_gray_700}`,
  },
  btn_cool_line_active: {
    color: gds_service.cool_gray_800,
    borderColor: gds_service.cool_gray_800,
    backgroundColor: gds_service.cool_gray_100,
  },

  btn_green: {
    color: gds_theme.white,
    backgroundColor: gds_service.green_700,
  },
  btn_green_hover: {
    backgroundColor: gds_service.green_800,
    textShadow: `0 0 ${gds_theme.white}`,
  },
  btn_green_active: {
    backgroundColor: gds_service.green_900,
  },

  btn_danger: {
    color: gds_theme.white,
    backgroundColor: gds_theme.danger,
  },
  btn_danger_hover: {
    backgroundColor: gds_system.danger_600,
    textShadow: `0 0 ${gds_theme.white}`,
  },
  btn_danger_active: {
    backgroundColor: gds_system.danger_700,
  },
  btn_navy: {
    color: gds_theme.white,
    backgroundColor: gds_service.primary_900,
  },
  btn_navy_hover: {
    backgroundColor: gds_service.primary_1000,
    textShadow: `0 0 ${gds_theme.white}`,
  },
  btn_navy_active: {
    backgroundColor: gds_theme.black,
  },
  btn_success: {
    color: gds_theme.white,
    backgroundColor: gds_system.success_700,
  },
  btn_success_hover: {
    backgroundColor: gds_system.success_800,
    textShadow: `0 0 ${gds_theme.white}`,
  },
  btn_success_active: {
    backgroundColor: gds_service.green_1000,
  },

  btn_error: {
    color: gds_theme.white,
    backgroundColor: gds_system.danger_600,
  },
  btn_error_hover: {
    backgroundColor: gds_system.danger_700,
    textShadow: `0 0 ${gds_theme.white}`,
  },
  btn_error_active: {
    backgroundColor: gds_system.danger_800,
  },
};

export const gds_textButtons = {
  size_lg: { ...gds_typo.ele1_m, ...gds_theme.textbtn_height_lg },
  size_md: { ...gds_typo.ele2_m, ...gds_theme.textbtn_height_md },
  size_sm: { ...gds_typo.ele3_m, ...gds_theme.textbtn_height_sm },

  btn_disabled: {
    color: `${gds_theme.gray_5_body} !important`,
  },
  btn_primary: {
    color: gds_theme.primary,
  },
  btn_primary_hover: {
    color: gds_service.primary_700,
    backgroundColor: gds_service.primary_100,
  },
  btn_primary_active: {
    color: gds_service.primary_800,
  },

  btn_black: {
    color: gds_theme.gray_9_title,
  },
  btn_black_hover: {
    backgroundColor: gds_theme.gray_1,
  },

  btn_gray: {
    color: gds_theme.gray_6,
  },
  btn_gray_hover: { backgroundColor: gds_theme.gray_1 },
};

//TODO: iconButtons
export const gds_iconButtons = {
  size_xl: { ...gds_theme.icon_btn_size_xl },
  size_lg: { ...gds_theme.icon_btn_size_lg },
  size_md: { ...gds_theme.icon_btn_size_md },
  size_sm: { ...gds_theme.icon_btn_size_sm },

  icon_btn_base: {
    padding: 0,
    borderRadius: "50%",
    ...gds_theme.btn_transition,
  },

  btn_disabled: {
    color: gds_theme.gray_5_body,
    borderColor: gds_theme.gray_5_body,
    backgroundColor: gds_theme.gray_2,
  },
  gray: { color: gds_theme.gray_7_text, background: gds_theme.white },
  gray_hover: { color: gds_theme.gray_9_title, background: gds_theme.white },
  gray_active: { color: gds_theme.gray_9_title, background: gds_theme.white },

  gray_fill: { color: gds_theme.gray_6, background: gds_theme.gray_0 },
  gray_fill_hover: {
    color: gds_theme.gray_7_text,
    background: gds_theme.gray_1,
  },
  gray_fill_active: { background: gds_theme.gray_2 },
};
